import { Stack, Switch, SwitchProps } from "@mui/material";
import { ReactEventHandler, ReactNode } from "react";
import { ColorString, blue, cyan, neutral } from "../colors";
import { FaroText } from "../text/faro-text/faro-text";

type SwitchPalette = {
  /** Color for the switch thumb */
  thumb: ColorString;

  /** Main color used for text and some borders */
  main: ColorString;

  /** Color used for the helper text */
  helpText: ColorString;

  /** Main color used for the track where the thumb slides */
  track: ColorString;

  /** Color used for the track border */
  trackBorder: ColorString;

  /** Color used for the track when the switch is checked*/
  checkedTrack: ColorString;

  /** Color used for the track border when the switch is checked */
  checkedTrackBorder: ColorString;

  /** Color used for the track when the control is hovered */
  hoverTrack: ColorString;
};

const LIGHT_PALETTE: SwitchPalette = {
  thumb: neutral[0],
  main: neutral[800],
  helpText: neutral[600],
  track: neutral[200],
  trackBorder: neutral[300],
  checkedTrackBorder: blue[600],
  hoverTrack: neutral[400],
  checkedTrack: blue[500],
};

const DARK_PALETTE: SwitchPalette = {
  thumb: neutral[950],
  main: neutral[100],
  helpText: neutral[300],
  track: neutral[800],
  trackBorder: neutral[300],
  checkedTrackBorder: cyan[300],
  hoverTrack: neutral[400],
  checkedTrack: cyan[400],
};

export type FaroSwitchProps = Pick<
  SwitchProps,
  | "checked"

  // Note: Setting this to `true` will only render the component as disabled,
  // while it's still possible to change "checked" state programmatically.
  | "disabled"
> & {
  /**
   * Called when user clicks on it, or pressed enter while the switch has focus.
   * Only called if the switch is not disabled.
   */
  onToggle?: ReactEventHandler<HTMLElement>;

  /** Optional label to show alongside the switch */
  label?: ReactNode;

  /** Position of the label, before or after the switch */
  labelPlacement?: "start" | "end";

  /** Optional HelpText to show below the switch */
  helpText?: ReactNode;

  /** True to use the dark variant of the switch @default false */
  dark?: boolean;

  /** True to stretch the switch to cover the entire parent width @default false */
  fullWidth?: boolean;
};

/** @returns a Switch styled following the FARO Design System with an optional label and help text */
export function FaroSwitch({
  checked,
  disabled,
  onToggle,
  label,
  labelPlacement = "start",
  helpText,
  dark = false,
  fullWidth = false,
}: FaroSwitchProps): JSX.Element {
  const palette = dark ? DARK_PALETTE : LIGHT_PALETTE;

  const hoverStyle = {
    ":hover": {
      color: palette.checkedTrack,
      cursor: "pointer",
      background: `${neutral[500]}26`,
      "& .MuiSwitch-switchBase": {
        background: "unset",
        "& +.MuiSwitch-track": {
          backgroundColor: palette.hoverTrack,
          border: `1px solid ${palette.hoverTrack}`,
        },
        "&.Mui-checked +.MuiSwitch-track": {
          backgroundColor: palette.checkedTrackBorder,
          border: `1px solid ${palette.checkedTrackBorder}`,
        },
      },
    },
  };

  return (
    <Stack
      sx={[
        disabled ? { color: palette.main, opacity: 0.5 } : {},
        {
          width: fullWidth ? "100%" : "fit-content",
        },
      ]}
    >
      <Stack
        direction={labelPlacement === "start" ? "row" : "row-reverse"}
        alignItems="center"
        gap={1}
        minHeight="1.75rem"
        // Note: When user pressed the space bar while the switch has focus, `onClick` will be triggered
        onClick={(ev) => !disabled && onToggle?.(ev)}
        onKeyDown={(ev) => {
          if (!disabled && ev.key === "Enter") {
            onToggle?.(ev);
          }
        }}
        tabIndex={0}
        sx={[
          disabled ? {} : hoverStyle,
          {
            color: palette.main,
            boxSizing: "border-box",
            borderRadius: "4px",
            userSelect: "none",
            px: "2px",
            py: "4px",
            "&:focus-visible": {
              px: "1px",
              py: "3px",
              border: `solid 1px ${palette.main}`,
              outline: 0,
            },
          },
        ]}
      >
        <FaroText
          variant="bodyM"
          color="inherit"
          flexGrow={1}
          textAlign={labelPlacement === "start" ? "left" : "right"}
        >
          {label}
        </FaroText>
        <Switch
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          checked={checked}
          disabled={disabled}
          autoFocus={false}
          focusRipple={false}
          // Disable internal focus handling as the focus is handled outside
          // to outline together the switch and the label
          inputProps={{
            tabIndex: -1,
          }}
          sx={{
            width: "2rem",
            height: "1rem",
            padding: 0,
            overflow: "visible",

            "& .MuiSwitch-switchBase": {
              width: "2rem",
              height: "1rem",
              padding: 0,

              "& .MuiSwitch-input": {
                // Make sure the input does not stretch over the provided space
                width: "100%",
                // Remove any left setting that the input might have to make sure it's put into the correct position
                left: "unset",
              },

              "&.Mui-checked": {
                transform: "translateX(16px)",
                ":hover": {
                  background: "unset",
                },
                "& + .MuiSwitch-track": {
                  backgroundColor: palette.checkedTrack,
                  border: `1px solid ${palette.checkedTrackBorder}`,
                  opacity: 1,
                },
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                opacity: 1,
                color: `${palette.thumb}`,
                border: `1px solid ${palette.main}80`,
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              color: palette.thumb,
              border: `1px solid ${palette.main}`,
              width: "1rem",
              height: "1rem",
              borderRadius: "0.5rem",
              padding: 0,
              margin: 0,
              transform: "translateX(-8px)",
              boxShadow: "unset",
            },
            "& .MuiSwitch-track": {
              maxWidth: "2rem",
              maxHeight: "1rem",
              padding: 0,
              boxSizing: "border-box",
              borderRadius: "0.5rem",
              background: palette.track,
              border: `1px solid ${palette.trackBorder}`,
              opacity: 1,
              transition: (theme) =>
                theme.transitions.create(["background-color"], {
                  duration: 500,
                }),
            },
          }}
        />
      </Stack>
      {helpText && (
        <FaroText
          variant="bodyS"
          color={disabled ? palette.main : palette.helpText}
        >
          {helpText}
        </FaroText>
      )}
    </Stack>
  );
}
