import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { PropsWithChildren, ReactNode } from "react";
import { TagTypes } from "../input/input-label-tag";

export type DropdownSelectProps = {
  /** The current value of the dropdown, undefined if no value has been selected yet */
  value?: string;

  /** The top label of the dropdown */
  label?: ReactNode;

  /** If true, the component is disabled  */
  disabled?: boolean;

  /** Add an error message */
  error?: boolean;

  /** Make the component width to stretch and be the same as the container */
  fullWidth?: boolean;

  /** Dark variant of the component */
  dark?: boolean;

  /** Help text showed at the bottom of the dropdown */
  helpText?: ReactNode;

  /** Optional tag added to the top label. If they label is not defined, the tag is not visible */
  tag?: TagTypes;

  /** The style applied to the select */
  sx?: SxProps<Theme>;

  /** Function to render the label of the selected value inside the dropdown */
  renderValue(selected: string | undefined): JSX.Element;

  /** The variants of the component */
  variant?: "underlined" | "outlined";

  /** Callback executed when the list menu is closed */
  onClose?(): void;

  /** Callback executed when the list menu is opened */
  onOpen?(): void;
} & PropsWithChildren;

export type DropdownProps = Omit<DropdownSelectProps, "renderValue"> & {
  /** Placeholder text for the dropdown */
  placeholder?: ReactNode;

  /** Options for the dropdown */
  options: DropdownOption[];

  /** Make the text inside the dropdown capitalized  */
  shouldCapitalize?: boolean;

  /** Callback executed when an option from the list is selected */
  onChange?(event: { target: { value: string } }): void;
};

/** Elements that can be displayed in the dropdown */
export type DropdownOption = Option | DividerOption;

export interface Option {
  /** A unique key of the given option */
  key: string;

  /** Value of the option */
  value: string;

  /** Label to display for the option */
  label: ReactNode;

  /** The secondary text of the option */
  secondaryText?: ReactNode;

  /** True if the option is disabled */
  isDisabled?: boolean;

  /** If true, the element is a non-selectable label */
  isHeader?: boolean;
}

export type DividerOption = {
  /** If true, the element is a non-selectable divider */
  isDivider: true;
};

/**
 * @returns true if the DropdownOption is a normal Option
 * @param option the DropdownOption to check
 */
export function isOption(option: DropdownOption): option is Option {
  return "key" in option && "value" in option && "label" in option;
}

/**
 * @returns true if the DropdownOption is a DividerOption
 * @param option the DropdownOption to check
 */
export function isDivider(option: DropdownOption): option is DividerOption {
  return "isDivider" in option && option.isDivider;
}
